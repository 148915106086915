<template>
    <div>
        <div v-if="id">
            <h1>MER {{ state.object.data.tracker_mer_id }}</h1>
            <div v-show="isCurrentView('details')">
                <div class="margin_bottom">
                    <button @click="merCreditNotification" class="btn btn-default">Send Credit-Ready Notification
                    </button>
                </div>
                <div class="panel panel-default">
                    <div class="panel-body details_container">
                        <h3 class="col-md-12">Info</h3>
                        <div class="row" style="margin-left: 0px">
                            <div class="col-lg-6">
                                <DetailsLink
                                    :field="{
                            name: 'tracker_id',
                            label: 'Order',
                            display: state.object.data.tracker_id,
                            link: 'tracker'}"
                                    :val="state.object.data.tracker_id"
                                />
                            </div>
                            <div class="col-lg-6">
                                <DetailsStatic
                                    :val="state.object.data.date_issued"
                                    :field="{
                                name: 'date_issued',
                                label: 'Date Issued'
                            }"
                                />
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <DetailsText
                                :val="state.object.data.contact_information"
                                :field="{
                                name: 'contact_information',
                                label: 'Contact',
                            }"
                                @save="save"
                            />
                        </div>
                        <div class="col-lg-6">
                            <DetailsStatic
                                :val="state.object.data.cdi_location"
                                :field="{
                                name: 'cdi_location',
                                label: 'Location'
                            }"
                            />
                        </div>
                        <div class="col-lg-6">
                            <DetailsTypeahead :field="{
                                            name: 'person_responsible_id',
                                            label: 'Person Responsible',
                                            display: state.object.data.person_responsible,
                                            cdiType: 'employee',
                                            link: 'user',
                                            canClear: true
                                          }"
                                              :val="state.object.data.person_responsible_id"
                                              @save="save"
                            />

                        </div>
                        <h3 class="col-md-12">Issue</h3>
                        <div class="col-lg-6">
                            <DetailsSelect
                                :field="{
                                    name: 'fault_group_id',
                                    label: 'Type',
                                    options: this.cache.trackerCache.TRACKER_MER_REMAKE_TYPES
                                }"
                                :val="state.object.data.fault_group_id"
                                @save="save"
                            />
                        </div>
                        <div class="col-lg-6">
                            <DetailsSelect
                                :field="{
                                    name: 'cause_id',
                                    label: 'Issue Description',
                                    options: this.cache.trackerCache.TRACKER_MER_CAUSE_TYPES
                                    }"
                                :val="state.object.data.cause_id"
                                @save="save"
                            />
                        </div>
                        <div class="col-lg-6" v-show="state.object.data.fault_group_id == 31">
                            <DetailsSelect
                                :field="{
                                    name: 'secondary_sales_id',
                                    label: 'Secondary Sales',
                                    options: this.cache.trackerCache.TRACKER_MER_SECONDARY_SALES
                                    }"
                                :val="state.object.data.secondary_sales_id"
                                @save="save"
                            />
                        </div>
                        <div class="col-lg-6" v-show="state.object.data.fault_group_id == 12">
                            <DetailsSelect
                                :field="{
                                    name: 'secondary_design_id',
                                    label: 'Design Secondary',
                                    options: this.cache.trackerCache.TRACKER_MER_SECONDARY_DESIGN
                                    }"
                                :val="state.object.data.secondary_design_id"
                                @save="save"
                            />
                        </div>
                        <div class="col-lg-6" v-show="state.object.data.fault_group_id == 13">
                            <DetailsSelect
                                :field="{
                                    name: 'secondary_operations_id',
                                    label: 'Operations Secondary',
                                    options: this.cache.trackerCache.TRACKER_MER_SECONDARY_OPERATIONS
                                    }"
                                :val="state.object.data.secondary_operations_id"
                                @save="save"
                            />
                        </div>
                        <div class="col-md-12">
                            <DetailsText
                                :val="state.object.data.issue_description"
                                :field="{
                                name: 'issue_description',
                                label: 'Description',
                                subLabel: '(max char 50)',
                                charLimit: 50
                            }"
                                @save="save"
                            />
                        </div>
                        <h3 class="col-md-12">Financial</h3>
                        <div class="col-md-6">
                            <DetailsNumber
                                :val="state.object.data.cdi_estimated_cost"
                                :field="{
                                    name: 'cdi_estimated_cost',
                                    label: 'CDI Total Cost',
                                    }"
                                @save="save"
                            />
                        </div>
                        <div class="col-md-6">
                            <DetailsNumber
                                :val="state.object.data.final_cost_to_cdi"
                                :field="{
                                    name: 'final_cost_to_cdi',
                                    label: 'Credit Amount',
                                    }"
                                @save="save"
                            />
                        </div>
                        <div class="col-md-6">
                            <DetailsDate
                                :field="{
                                    name: 'credit_approval_date',
                                    label: 'Credit Approval Date'}"
                                :val="state.object.data.credit_approval_date"
                                @save="save"
                            />
                        </div>
                        <div class="col-md-6">
                            <DetailsCheckbox
                                :field="{
                                    name: 'credit_pending',
                                    label: 'Pending Credit'}"
                                :val="state.object.data.credit_pending"
                                @save="save"
                            />
                        </div>
                        <div class="col-md-6">
                            <DetailsButton
                                :field="{
                                        name: 'credit_id',
                                        label: 'Credit',
                                        buttonLabel: 'Create',
                                        endPoint: 'issue_credit',
                                        link: 'credit',
                                    }"
                                :val="state.object.data.credit_id"
                                @post="createCredit"
                            />
                        </div>
                        <br/>
                        <h3 class="col-md-12">Resolution</h3>
                        <div class="col-lg-6">
                            <DetailsSelect
                                :field="{
                                    name: 'resolution_id',
                                    label: 'Resolution',
                                    options: this.cache.trackerCache.TRACKER_MER_RESOLUTIONS
                                    }"
                                :val="state.object.data.resolution_id"
                                @save="save"
                            />
                        </div>
                        <div class="col-md-6" v-show="state.object.data.resolution_id">
                            <DetailsCheckbox
                                :field="{
                                    name: 'cdi_issue_closed',
                                    label: 'MER Closed'
                                }"
                                :val="state.object.data.cdi_issue_closed"
                                @save="save"
                            />
                        </div>
                    </div>
                  <div class="margin_bottom col-lg-12" v-show="!remake">
                    <h3>Remake</h3>
                    <div class="margin_bottom col-lg-12" v-if="state.object.data.tracker_id && remakeTrackerIDs">{{remakeTracker}}
                      <div v-for="(remake, index) in remakeTrackerIDs" v-bind:key="index">
                        <p>The order number for the remake is #{{ remake.tracker_id }}
                          <router-link :to='`/tracker/${remake.tracker_id}`'>Continue to remake order</router-link>
                        </p>
                      </div>
                    </div>
                    <button v-show="remakeTrackerIDs.length == 0" @click="showRemake=true" class="btn btn-default">Start Remake</button>
                  </div>
                  <div class="col-lg-12" v-show="showRemake && !remake">
                    <form>
                      <table class='table'>
                        <tbody>
                        <tr>
                          <td><input type=radio v-model='remakeOption' value="15"></td>
                          <td><label>Customer</label><br>Copy Order, Flag as Customer remake, and copy full invoice
                          </td>
                        </tr>
                        <tr>
                          <td><input type=radio v-model='remakeOption' value="12"></td>
                          <td><label>Design</label><br>Copy Order, Flag as Commission remake, and set invoice to $0
                          </td>
                        </tr>
                        <tr>
                          <td><input type=radio v-model='remakeOption' value="14"></td>
                          <td><label>Freight</label><br>Copy Order, Flag as Freight remake, and set invoice to $0</td>
                        </tr>
                        <tr>
                          <td><input type=radio v-model='remakeOption' value="11"></td>
                          <td><label>Order Entry</label><br>Copy Order, Flag as Commission remake, and set invoice to
                            $0
                          </td>
                        </tr>
                        <tr>
                          <td><input type=radio v-model='remakeOption' value="13"></td>
                          <td><label>Production</label><br>Copy Order, Flag as Commission remake, and set invoice to
                            $0
                          </td>
                        </tr>
                        <tr>
                          <td><input type=radio v-model='remakeOption' value="16"></td>
                          <td><label>Reorder</label><br>Copy Order, Flag as Reorder, and copy full invoice</td>
                        </tr>
                        <tr>
                          <td><input type=radio v-model='remakeOption' value="31"></td>
                          <td><label>Sales</label><br>Copy Order, Flag as Commission remake, and set invoice to $0
                          </td>
                        </tr>
                        <tr>
                          <td><input type=radio v-model='remakeOption' value="127"></td>
                          <td><label>Miscellaneous</label><br>Copy Order and set invoice to $0</td>
                        </tr>
                        </tbody>
                      </table>
                      <div class="form-group"><label>*New Scheduled Ship Date</label>
                        <DatePicker v-model='scheduledShip' :input-attributes="{class: 'form-control'}"/>
                      </div>
                      <div class="form-group"><label>New PO</label><input v-model='distributorPo' class="form-control">
                      </div>
                      <div class="form-group" v-show="remakeOption==127"><label>Misc Description</label><span class="remakeDescription">(max char 30)</span><input maxlength="30" style="width: 100%" type='text' v-model='miscDescription'>
                      </div>
                      <div class="form-group"><label>Issue Description</label><span class="remakeOption">(max char 50)</span><textarea maxlength="50" v-model='issueDescription' class="form-control"></textarea>
                      </div>
                      <div class="form_grid">
                        <label><input type='checkbox' :true-value='1' :false-value=null
                                      name='params[send_confirmation_email]' v-model='sendConfirmationEmail'> Send
                          confirmation email</label></div>

                      <input @click='remakeOrder' type=button value='Remake Order' class="btn btn-default">
                      <input @click='cancelRemake' type=button value='Cancel' class="btn btn-default">

                      <br/>
                    </form>
                  </div>
                  <div class="col-lg-12" v-show='remake'>
                    <p>Order Remade! <span v-show='remake.duplicate_po' class='text-warning'>Warning! Duplicate PO</span>
                    </p>
                    <p>The order number for the remake is #{{ remake.tracker_id }}</p>
                    <p>
                      <router-link :to='`/tracker/${remake.tracker_id}`'>Continue to remake order</router-link>
                    </p>
                    <p><a class="pointer" @click="cancelRemake">Return to current order</a></p>
                  </div>
                </div>
            </div>

            <Notebook v-show="isCurrentView('notebook')" :notes="state.object.data.notes"/>
            <Files v-show="isCurrentView('notebook')" :files="state.object.data.files"/>
            <History v-show="isCurrentView('history')"/>
        </div>
        <Search v-else/>
    </div>
</template>

<script>
    import { store } from '@/store/BusinessObject.store.js';
    import { trackerMerStore } from './TrackerMer.store.js';
    import Search from '@/components/businessObjects/utils/Search';
    import History from '@/components/businessObjects/utils/History';
    import Notebook from "@/components/businessObjects/utils/Notebook";
    import Files from "@/components/businessObjects/utils/Files";
    import DetailsText from "@/components/businessObjects/utils/DetailsText";
    import DetailsLink from "@/components/businessObjects/utils/DetailsLink";
    import DetailsSelect from "@/components/businessObjects/utils/DetailsSelect";
    import trackerCache from '@/cache/tracker.cache.js';
    import DetailsStatic from "@/components/businessObjects/utils/DetailsStatic";
    import DetailsNumber from "@/components/businessObjects/utils/DetailsNumber";
    import DetailsButton from "@/components/businessObjects/utils/DetailsButton";
    import DetailsCheckbox from "@/components/businessObjects/utils/DetailsCheckbox";
    import DetailsDate from "@/components/businessObjects/utils/DetailsDate";
    import appStore from "@/store/App.store";
    import DetailsTypeahead from "@/components/businessObjects/utils/DetailsTypeahead.vue";
    import DatePicker from "@/components/utils/DatePicker.vue";
    export default {
        name: "Container",
        data() {
            return {
                state: store.state,
                appState: appStore.state,
                trackerMerState: trackerMerStore.state,
                cache: {
                    trackerCache,
                },
              showRemake: false,
              remake: false,
              remakeOption: 11,
              scheduledShip: '',
              sendConfirmationEmail: 1,
              issueDescription: '',
              miscDescription: '',
              distributorPo: '',
              remakeTrackerIDs: []
            }
        },
        props: ['id'],
        components: {
          DetailsTypeahead,
            Files,
            Notebook,
            Search,
            History,
            DetailsText,
            DetailsLink,
            DetailsSelect,
            DetailsStatic,
            DetailsNumber,
            DetailsButton,
            DetailsCheckbox,
            DetailsDate,
            DatePicker
        },
        created() {
            store.initialize(this.trackerMerState);
            this.load();
        },
        methods: {
            load: function() {
                if (this.id){
                    store.load(this.id)
                        .then(() => this.$appStore.setTitle([`${this.state.title} ${this.state.object.data.tracker_mer_id}`]));
                }
            },
            isCurrentView: function(view) {
                return view === this.state.currentView;
            },
            merCreditNotification: function () {
                store.api('send_mer_credit_notification');
            },
            save: function(data) {
                store.save(data.field, data.val);
               if(data.field =="cdi_issue_closed" && data.val == 1){
                   let note = 'MER '+ this.state.object.id + ' has been closed by ' + this.appState.user.userName;
                   store.api('add_note', {'params[cdi_note]': note}, 'object.data');
               }
            },
            createCredit: function(request) {
                let scope = this;
                store.api(request.endPoint).then(() => scope.load());
            },
            cancelRemake: function () {
              this.showRemake = false
              this.resetForm();
            },
            resetForm: function () {
              this.remakeOption = 11;
              this.scheduledShip = '';
              this.distributorPo = '';
              this.sendConfirmationEmail = 1;
              this.remake = false;
            },
          remakeOrder: function () {
            let params = {
              'params[tracker_id]': this.state.object.data.tracker_id,
              'params[remake_option]': this.remakeOption,
              'params[scheduled_ship]': this.scheduledShip,
              'params[distributor_po]': this.distributorPo,
              'params[issue_description]': this.issueDescription,
              'params[misc_description]': this.miscDescription,
              'params[send_confirmation_email]': this.sendConfirmationEmail
            };

            store.apiGeneric('tracker/remake', params).then((data) => {
              if (data.opt === 'get') {
                this.resetForm();
                this.remake = data;
              }
            })
          },
          checkForRemake: function() {
            let params = {
              'params[remake_tracker_id]': this.state.object.data.tracker_id
            }
            store.apiGeneric('tracker/get_tracker_remakes', params ).then(result => {
              this.remakeTrackerIDs = result;
            });
          },
        },
        computed: {
            currentView: function() {
                return this.state.currentView;
            },
          remakeTracker:function (){
            return this.checkForRemake()
          },
    },
    watch: {
        id: function () {
            this.load();
        },
        currentView: function () {
            if (this.state.currentView === 'history') {
                store.getHistory();
            } else if (this.currentView == 'search') {
                window.location.reload()
            }
        }
    }
}
</script>

<style scoped>

</style>